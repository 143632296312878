import WasmController from "react-lib/frameworks/WasmController";

// APIs
// USER
import CreateUserAPIViewM from "issara-sdk/apis/CreateUserAPIViewM";
import UserSetPassword2API from "issara-sdk/apis/UserSetPassword2API_users";
import UserDetailAPIViewM from "issara-sdk/apis/UserDetailAPIViewM";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import UserSetPasswordAPIM from "issara-sdk/apis/UserSetPasswordAPI_usersM";
import UserDetailAPIView from "issara-sdk/apis/UserDetailAPIView_users";
import UserProfileDetailAPIView from "issara-sdk/apis/UserProfileDetailAPIView_users";
import DivisionHasUserListForOtherUser from "issara-sdk/apis/DivisionHasUserListForOtherUser_apps_PRX";
import DivisionHasUserDetailForOtherUser from "issara-sdk/apis/DivisionHasUserDetailForOtherUser_apps_PRXM";
import RoleList from "issara-sdk/apis/RoleList_users";
import UserRetrieveUpdateDestroyAPI from "issara-sdk/apis/UserRetrieveUpdateDestroyAPI_users";
// CORE
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import DoctorList from "issara-sdk/apis/DoctorList_core";
// PRX
import UserDivisionHasUserList from "issara-sdk/apis/UserDivisionHasUserList_apps_PRX";
// QUE
import DoctorProviderView from "issara-sdk/apis/DoctorProviderView_apps_QUE";

import moment from "moment";

// Comon
import GrayLogger from "react-lib/apps/common/GrayLoggerClass";

// Utils
import { adToBeWithSetFormat, beToAd } from "react-lib/utils/dateUtils";
import { DATE_FORMAT } from "react-lib/apps/Scheduling/common/Utils";

// Config
import CONFIG from "config/config";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Const
const STAFF_DETAIL_EXTRA = "StaffDetailExtra";
const STAFF_DETAIL_EXTRA_COLLECTION = `${CONFIG.PREFIX_COLLECTION_NAME}${STAFF_DETAIL_EXTRA}`;

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;
  staffDetail?: {
    [key: string]: any;
    id: number | string;
    extra: StaffDetailExtraType;
  } | null;
  masterOptions?: any;
  userSelectedLanguage?: "en" | "th";

  // StaffSetting
  staffList?: any;
  profileUser?: {
    staff_extra?: StaffDetailExtraType;
    [key: string]: any;
  } | null;
  settingsUser?: any;
  changePassword?: any;
  profileEmployee?: Partial<{
    birth_date: any;
    flag_end_date: any;
    flag_start_date: any;
    position: number;
    status: number;
    isCreateDoctor: boolean;
  }>;
  profileDivision?: {
    data: Record<string, any>[];
    raw: Record<string, any>[];
  };
  // divisionOptions?: any;
  roleOptions?: any[];
};

export type StaffDetailExtraType = Partial<{
  user_id: number;
  doctor_id: number;
  member_group_permission: string[];
}>;

export const StateInitial: State = {
  staffList: {},
  profileUser: {},
};

export type Event =
  | { message: "SearchStaff"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "SettingStaff"; params: any }
  | { message: "SettingDivision"; params: {} };

export type Data = {
  division?: number;
  masterData?: any;
  userProfile?: any;
};

export const DataInitial = {};

export const STAFF_LIMIT = 20;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const SearchStaff: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["gender", {}],
        ["nationality", {}],
        ["positionAll", {}],
        ["prenameEn", {}],
        ["prenameTh", {}],
        ["specialty", {}],
        ["role", {}],
        ["doctor", {}],
        ["doctorType", {}],
        ["division", {}],
      ],
    },
  });

  if (params.fetchOptionsOnly) {
    return;
  }

  const activePage = params.activePage || 1;
  const filter = params.filter || {};
  const offset = (activePage - 1) * STAFF_LIMIT;

  const state = controller.getState();

  const [r, e, n] = await UserDivisionHasUserList.list({
    params: {
      first_name__icontains: filter.firstName,
      last_name__icontains: filter.lastName,
      employee__code__icontains: filter.searchCode,
      employee__position: filter.position,
      username__icontains: filter.username,
      divisionhasuser__division: filter.organization,
      limit: STAFF_LIMIT,
      offset: offset,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  let roleOptions = state.roleOptions || [];

  if (e) {
    console.error(e);
  }

  if (!roleOptions.length) {
    const role = await RoleList.list({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    roleOptions = role?.[0]?.items;
  }

  const items = (r?.items || []).map((item: any) => ({
    ...item,
    pre_name: item.pre_name || item.employee?.pre_name || item.profile?.pre_name,
  }));

  controller.setState({
    staffList: {
      total: r?.total || 0,
      items: items,
      activePage: activePage,
    },
    roleOptions,
  });
};

export const SettingStaff: Handler = async (controller, params) => {
  var state = controller.getState();

  if (params.action === "SAVE") {
    // controller.setState({
    //   buttonLoadCheck: {
    //     ...state.buttonLoadCheck,
    //     [params.buttonLoadKey]: "LOADING",
    //   },
    // });
    // const profileUser = state.profileUser || {};
    // if (params.selectedRow?.id) {
    //   const data = {
    //     pre_name: profileUser.pre_name,
    //     first_name: profileUser.first_name || "",
    //     last_name: profileUser.last_name || "",
    //     image: state.user?.image,
    //     email: profileUser.email,
    //     citizen_passport: profileUser.cid || "",
    //     cid: profileUser.cid || "",
    //   };
    //   if (
    //     typeof data.image === "string" ||
    //     data.image === null ||
    //     data.image === undefined
    //   ) {
    //     delete data.image;
    //   }
    //   const [userResp, userErr] = await UserDetailAPIViewM.patch({
    //     pk: params.selectedRow.id,
    //     apiToken: controller.apiToken,
    //     extra: { division: controller.data.division },
    //     data: data,
    //   });
    //   if (userErr) {
    //     SetErrorMessage(controller, { ...params, error: userErr });
    //   } else {
    //     await PatchUserEmployeeDetail(controller, params);
    //     const [profileResp] = await PatchUserProfileDetail(controller, {
    //       user: userResp,
    //     });
    //     if (params.selectedRow.id === controller.data.userProfile?.id) {
    //       const [preFlight, userProfile] = await Promise.all([
    //         preflightM.get({
    //           apiToken: controller.apiToken,
    //         }),
    //         UserDetailAPIView.retrieve({
    //           apiToken: controller.apiToken,
    //           pk: params.selectedRow.id,
    //         }),
    //       ]);
    //       controller.setData({ userProfile: userProfile[0] });
    //       controller.setState({ django: { ...preFlight[0] } });
    //     }
    //     controller.setState({
    //       successMessage: {
    //         ...state.successMessage,
    //         [params?.card]: profileResp,
    //       },
    //       errorMessage: {
    //         ...state.errorMessage,
    //         [params.card]: { error: null },
    //       },
    //       buttonLoadCheck: {
    //         ...state.buttonLoadCheck,
    //         [params.buttonLoadKey]: "SUCCESS",
    //       },
    //       profileUser: state.profileUser,
    //       user: userResp,
    //     });
    //   }
    // } else {
    //   const [userResp, userErr] = await CreateUserAPI(controller, {});
    //   if (userErr) {
    //     SetErrorMessage(controller, { ...params, error: userErr });
    //   } else {
    //     const [profileResp, profileErr] = await PatchUserProfileDetail(
    //       controller,
    //       { user: userResp }
    //     );
    //     controller.setState({
    //       successMessage: {
    //         ...state.successMessage,
    //         [params?.card]: profileErr,
    //       },
    //       errorMessage: {
    //         ...state.errorMessage,
    //         [params.card]: { error: null },
    //       },
    //       buttonLoadCheck: {
    //         ...state.buttonLoadCheck,
    //         [params.buttonLoadKey]: "SUCCESS",
    //       },
    //       profileUser: state.profileUser,
    //       user: userResp,
    //     });
    //   }
    // }
  } else if (params?.action === "OPEN_CHANGE") {
    // const user = await UserDetailAPIView.retrieve({
    //   pk: params.id,
    //   apiToken: controller.apiToken,
    //   extra: { division: controller.data.division },
    // });
    // controller.setState({ user: user?.[0] });
  } else if (params?.action === "CHANGE_PASSWORD") {
    if (params.approvalPassword !== undefined) {
      // * Update รหัสที่ 2 ใช้ได้เฉพาะการ approve
      const [accountRes, accountErr] = await UserRetrieveUpdateDestroyAPI.patch({
        apiToken: controller.apiToken,
        data: { use_sec_pass_for_approve: params.approvalPassword },
      });

      if (accountErr) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: accountErr },
          },
        });
      } else {
        controller.setState({
          settingsUser: {
            ...state.settingsUser,
            use_sec_pass_for_approve: params.approvalPassword,
          },
        });
      }
    }

    if (params.changePass1 && params.changePass2) {
      const [changePass1Resp, changePass1Err] = await PostUserSetPassword(controller, {
        ...params,
        noShowSuccess: true,
      });

      if (!changePass1Err) {
        PostUserSetPassword(controller, { ...params, pass2: true });
      }
    } else if (params.changePass2) {
      PostUserSetPassword(controller, { ...params, pass2: true });
    } else if (params.changePass1) {
      PostUserSetPassword(controller, params);
    } else if (typeof params.approvalPassword !== "undefined") {
      controller.setState({
        successMessage: { ...state.successMessage, [params.card]: true },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
      });
    }
  } else if (params?.action === "EDIT") {
    const [user, [employee], staffDetail] = await Promise.all([
      UserDetailAPIView.retrieve({
        pk: params.id,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      }),
      UserEmployeeDetailAPIView.retrieve({
        pk: params.id,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      }),
      GetStaffDetail(controller, {
        userId: !params.showMemberGroupPermission ? null : params.profile?.user,
      }),
    ]);

    if (!!user?.[1]) {
      return;
    }

    let doctor: any[] = [null, null, null];

    if (user[0].doctor !== null) {
      doctor = await DoctorDetail.retrieve({
        pk: user?.[0]?.doctor,
        apiToken: controller.apiToken,
        extra: { division: controller.data.division },
      });
    }

    const doctorDetail = doctor?.[0] || {};

    const position =
      controller.data.masterData?.positionAll?.find(
        (position: any) => position.id === employee?.position
      ) || {};

    const genderMapping = {
      0: "N",
      1: "M",
      2: "F",
    };

    const profileUser = params.profile
      ? {
          ...(employee && {
            first_name_en: employee?.first_name_en,
            pre_name_en: employee?.pre_name_en,
            last_name_en: employee?.last_name_en,
            birth_date: employee?.birth_date,
            username: user?.[0]?.username,
          }),
          ...params.profile,
          phone_no: employee?.phone_no || params.profile?.phone_no,
          gender: employee?.gender || genderMapping[params.profile?.gender ?? ""],
          doctor_type: doctorDetail?.type,
          doctor_languages: doctorDetail?.languages,
          staff_extra: staffDetail?.extra || {},
        }
      : null;

    const profileEmployee = employee
      ? {
          ...employee,
          birth_date: adToBeWithSetFormat(employee.birth_date),
          flag_start_date: adToBeWithSetFormat(employee.flag_start_date),
          flag_end_date: adToBeWithSetFormat(employee.flag_end_date),
          isCreateDoctor: !!doctorDetail?.id || !!position.teacher_flag || !!position.student_flag,
        }
      : employee;

    controller.setState({
      profileEmployee: profileEmployee
        ? {
            ...profileEmployee,
            pre_name: profileEmployee?.pre_name || profileUser?.pre_name,
          }
        : profileEmployee,
      profileUser,
      settingsUser: {
        ...user?.[0],
        certificate_no: doctorDetail?.certificate_no || "",
        specialty: doctorDetail?.specialty || "",
        codeDoctor: doctorDetail?.code || "",
        specialties: doctorDetail?.specialties,
      },
    });
  } else if (params?.action === "SAVE_EMPLOYEE") {
    HandleSaveEmployee(controller, params);
  } else if (params.action === "SEARCH") {
    // const role = await RoleList.list({
    //   params: {
    //     name__icontains: params.name,
    //   },
    //   apiToken: controller.apiToken,
    // });
    // controller.setState({
    //   roleOptions: role?.[0]?.items,
    // });
  }
};

const HandleSaveEmployee: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  let userId = params.id;

  const birthDate = (beToAd(state.profileEmployee?.birth_date) as moment.Moment).format(
    DATE_FORMAT
  );
  const flagEndDate = state.profileEmployee?.flag_end_date
    ? (beToAd(state.profileEmployee?.flag_end_date) as moment.Moment).format(DATE_FORMAT)
    : undefined;
  const flagStartDate = (beToAd(state.profileEmployee?.flag_start_date) as moment.Moment).format(
    DATE_FORMAT
  );

  const data = {
    ...state.settingsUser,
    is_active: state.profileEmployee?.status === 1,
  };

  if (typeof data.image === "string" || data.image === null || data.image === undefined) {
    delete data.image;
  }

  // สร้าง user ก่อนหากไม่มี user
  if (!state.settingsUser?.id) {
    let [userRes, userErr] = await CreateUserAPI(controller, {});

    if (userErr) {
      const isDuplicateUser =
        typeof userErr === "string" &&
        userErr.search(
          new RegExp(`Duplicate entry.*${state.profileUser?.username || ""}.*username`)
        ) >= 0;

      if (isDuplicateUser) {
        userErr = {
          username: [
            ["us", "en", "EN"].includes(state.userSelectedLanguage || "")
              ? "A user with that username already exists."
              : "ชื่อผู้ใช้นี้มีผู้อื่นใช้แล้ว ลองใช้ชื่ออื่น",
          ],
        };
      }

      return SetErrorMessage(controller, { ...params, error: userErr });
    } else {
      userId = userRes.id;
    }
  }

  // update user profile
  // #await PatchUserProfileDetail(controller, {
  //   user: { ...state.profileUser, id: userId },
  //   nameOnly: params.nameOnly ?? true,
  // });

  const [employeeResp, employeeErr] = await PatchUserEmployeeDetail(controller, {
    userId,
    data: {
      ...state.profileEmployee,
      flag_end_date: flagEndDate,
      flag_start_date: flagStartDate,
      birth_date: birthDate,
    },
  });

  if (employeeErr) {
    SetErrorMessage(controller, { ...params, error: employeeErr });

    HandleSetUserDetail(controller, { employee: employeeResp, userId, data });
  } else {
    let doctorId = state.settingsUser?.doctor || null;
    let doctorType = state.profileUser?.doctor_type || null;
    let doctorLanguages = state.profileUser?.doctor_languages || [];

    if (params.createDoctor) {
      let doctorRes: any = {};

      // หากมี doctor แล้วให้ update
      const isDoctor = state.settingsUser?.doctor || state.profileEmployee?.isCreateDoctor;

      if (isDoctor) {
        doctorRes = await CreateUpdateDoctor(controller, { ...params, userId });
      }

      doctorId = doctorRes.id || null;
      doctorType = doctorRes.type || null;
      doctorLanguages = doctorRes.languages || null;

      if (!doctorId && isDoctor) {
        HandleSetUserDetail(controller, { employee: employeeResp, userId, data });

        return;
      }
    }

    // update user detail
    const [userResp, userErr] = await PatchUserDetail(controller, {
      userId,
      data,
    });

    if (userErr) {
      return SetErrorMessage(controller, { ...params, error: userErr });
    }

    const userDetail = { ...state.settingsUser, ...userResp, doctor: doctorId };
    let staffDetail: any = {};

    if (params.showMemberGroupPermission) {
      staffDetail = await UpdateStaffDetail(controller, userDetail);
    }

    controller.setState({
      successMessage: { ...state.successMessage, [params.card]: userResp },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: null },
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "SUCCESS",
      },
      profileEmployee: employeeResp
        ? {
            ...employeeResp,
            birth_date: adToBeWithSetFormat(employeeResp.birth_date),
            flag_start_date: adToBeWithSetFormat(employeeResp.flag_start_date),
            flag_end_date: adToBeWithSetFormat(employeeResp.flag_end_date),
            isCreateDoctor: !!doctorId,
          }
        : employeeResp,
      profileUser: {
        ...state.profileUser,
        ...employeeResp,
        ...userResp,
        doctor_type: doctorType,
        doctor_languages: doctorLanguages,
        staff_extra: staffDetail,
      },
      settingsUser: userDetail,
      // string or number
      ...(!!state.staffDetail?.id && state.staffDetail.id == userDetail.id
        ? {
            staffDetail: { id: userDetail.id, extra: staffDetail },
          }
        : {}),
    });

    params.onSuccess?.();
  }
};

const HandleSetUserDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const { employee, userId, data } = params;

  // update user detail
  const [userResp, userErr] = await PatchUserDetail(controller, {
    userId: userId,
    data: data,
  });

  if (userErr) {
    return SetErrorMessage(controller, { ...params, error: userErr });
  }

  const userDetail = { ...state.settingsUser, ...userResp };
  let staffDetail: any = {};

  if (params.showMemberGroupPermission) {
    staffDetail = await UpdateStaffDetail(controller, userDetail);
  }

  controller.setState({
    profileEmployee: employee
      ? {
          ...employee,
          birth_date: adToBeWithSetFormat(employee.birth_date),
          flag_start_date: adToBeWithSetFormat(employee.flag_start_date),
          flag_end_date: adToBeWithSetFormat(employee.flag_end_date),
          isCreateDoctor: !!state.profileEmployee?.isCreateDoctor,
        }
      : state.profileEmployee,
    profileUser: {
      ...state.profileUser,
      ...employee,
      ...userResp,
      staff_extra: staffDetail,
    },
    settingsUser: userDetail,
    // string or number
    ...(!!state.staffDetail?.id && state.staffDetail.id == userDetail.id
      ? {
          staffDetail: { id: userDetail.id, extra: staffDetail },
        }
      : {}),
  });
};

export const SettingDivision: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "FETCH_DATA") {
    const divisionHasUser = await GetDivisionhasUserList(controller, params);

    controller.setState({
      profileDivision: {
        data: [...divisionHasUser],
        raw: JSON.parse(JSON.stringify(divisionHasUser)),
      },
    });
  } else if (params.action === "ADD_DIVISION") {
    const profileDivision: any[] = state.profileDivision?.data || [];

    const divisions = params.selectedDivision.map((option: any) =>
      controller.data.masterData.division.find((item: any) => item.id === option.value)
    );

    const divisionList = divisions.map((item: any, index: number) => ({
      division: {
        id: item.id,
        name: item.name,
        code: item.code,
        type_label: item.type_label,
      },
      id: `${new Date().toString()}-${index + 1}`,
      role: null,
      triage_level: "all",
      user: params.id,
      extra: {
        is_Active: true,
        useRights: [],
      },
    }));

    profileDivision.push(...divisionList);

    controller.setState({
      profileDivision: {
        data: [...profileDivision],
        raw: state.profileDivision?.raw || [],
      },
    });
  } else if (params.action === "SAVE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const divisions = (state.profileDivision?.data || []).map((item) => ({
      ...item,
      id: typeof item.id === "string" ? null : item.id,
    }));

    const promiseArr = divisions.map((item: any) =>
      CreateUpdateDivisionHasUser(controller, { dhu: item })
    );

    const response = await Promise.all(promiseArr);
    const isError = response.every((item: any) => item[1]);

    const divisionHasUser = await GetDivisionhasUserList(controller, params);

    if (isError) {
      SetErrorMessage(controller, { ...params, error: response[0][1] });
    } else {
      controller.setState({
        profileDivision: {
          data: [...divisionHasUser],
          raw: JSON.parse(JSON.stringify(divisionHasUser)),
        },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });

      params.onSuccess?.();
    }
  } else if (params.action === "DELETE_DIVISION") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const data = params.data;

    let result: any[] = [null, null, null];

    if (typeof data.id === "number") {
      result = await CreateUpdateDivisionHasUser(controller, {
        dhu: params.data,
        delete: true,
      });
    }

    if (result[1]) {
      SetErrorMessage(controller, { ...params, error: result[1] });
    } else {
      const divisionHasUser = (state.profileDivision?.data || []).filter(
        (item: any) => !(item.id === data.id && item.user === data.user)
      );

      controller.setState({
        profileDivision: {
          data: [...divisionHasUser],
          raw: JSON.parse(JSON.stringify(divisionHasUser)),
        },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });

      params.onSuccess?.();
    }
  } else if (params.action === "SEARCH") {
    // const division = await DivisionList.list({
    //   params: {
    //     name: params.name,
    //   },
    //   apiToken: controller.apiToken,
    // });
    // controller.setState({
    //   divisionOptions: division?.[0]?.items,
    // });
  }
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
// #const PatchUserProfileDetail: Handler = async (controller, params) => {
//   const state = controller.getState();
//   const user = params.user || {};
//   const profileUser = state.profileUser || {};
//   return UserProfileDetailAPIView.patch({
//     pk: user.id,
//     apiToken: controller.apiToken,
//     extra: { division: controller.data.division },
//     data: {
//       first_name: user.first_name || "",
//       last_name: user.last_name || "",
//       pre_name: user.pre_name,
//       phone_no: profileUser.phone_no,
//       ...(!params.nameOnly
//         ? {
//             last_visit_follow_page: new Date(),
//             image_url: user.image,
//             nationality: controller.data.masterData?.nationality?.filter(
//               (acc: any) => acc.name === "ไทย"
//             )?.[0]?.id,
//             gender: profileUser.gender || 0,
//             email: profileUser.email,
//           }
//         : {}),
//     },
//   });
// };

const CreateUpdateDoctor: Handler = async (controller, params) => {
  const state = controller.getState();

  const profileUser = state.profileUser || {};
  const user = state.settingsUser || {};

  const data = {
    code: user.codeDoctor || "",
    pre_name: profileUser.pre_name,
    first_name: profileUser.first_name,
    last_name: profileUser.last_name,
    first_name_en: profileUser.first_name_en,
    pre_name_en: profileUser.pre_name_en,
    last_name_en: profileUser.last_name_en,
    certificate_no: user.certificate_no,
    specialties: user.specialties || [],
    user: params.userId,
    type: profileUser.doctor_type,
    languages: profileUser.doctor_languages,
  };

  if (!user.doctor) {
    data.languages = [1];
  }

  const api = user.doctor ? DoctorDetail.patch : DoctorList.create;

  const result = await api({
    pk: user.doctor,
    data,
    apiToken: controller.apiToken,
  });

  if (result[1]) {
    const error = result[1];

    if (error.code) {
      error.codeDoctor = error.code;

      delete error.code;
    }

    SetErrorMessage(controller, {
      ...params,
      error,
    });
  }

  await CreateDoctorProvider(controller, { doctor: result[0]?.id });

  return result[0] || {};
};

const CreateDoctorProvider: Handler = async (controller, params) => {
  if (!params.doctor) {
    return [];
  }

  return DoctorProviderView.create({
    apiToken: controller.apiToken,
    data: { doctor: params.doctor },
  });
};

const CreateUserAPI: Handler = async (controller) => {
  const state = controller.getState();

  const profileUser = state.profileUser || {};

  const data = {
    pre_name: profileUser.pre_name,
    first_name: profileUser.first_name || "",
    last_name: profileUser.last_name || "",
    image: state.settingsUser?.image,
    email: profileUser.email,
    citizen_passport: profileUser.cid || "",
    cid: profileUser.cid || "",
    username: profileUser.username || "",
    password: profileUser.password,
    confirm_password: profileUser.confirm_password,
    secondary_password: profileUser.secondary_password,
    confirm_secondary_password: profileUser.confirm_secondary_password,
  };

  if (typeof data.image === "string" || data.image === null || data.image === undefined) {
    delete data.image;
  }

  return CreateUserAPIViewM.create({
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
    data: data,
  });
};

const PatchUserEmployeeDetail: Handler = (controller, params) => {
  const state = controller.getState();

  const profileUser = state.profileUser || {};

  return UserEmployeeDetailAPIView.patch({
    pk: state.settingsUser?.id || params.userId,
    data: {
      ...(params.data || {}),
      first_name: profileUser.first_name || "",
      last_name: profileUser.last_name || "",
      pre_name: profileUser.pre_name,
      first_name_en: profileUser.first_name_en,
      pre_name_en: profileUser.pre_name_en,
      last_name_en: profileUser.last_name_en,
      phone_no: profileUser.phone_no,
      gender: profileUser.gender,
    },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
};

const PostUserSetPassword: Handler = async (controller, params) => {
  const state = controller.getState();

  const changePass = state.changePassword || {};

  const password: string | undefined = params.pass2 ? changePass.password2 : changePass.password1;
  const confirmPassword = params.pass2
    ? changePass.confirm_password2
    : changePass.confirm_password1;

  const isInvalidPassword = password?.search(/^\d{6}$/) === -1;

  if (params.pass2 && password && isInvalidPassword) {
    const error = { password: ["กรุณาระบุด้วยตัวเลข 0-9 จำนวน 6 ตัว"] };

    controller.setState({ errorMessage: { ...state.errorMessage, [params.card]: { error } } });

    return [null, error];
  }

  const api = params.pass2 ? UserSetPassword2API.post : UserSetPasswordAPIM.post;

  const [changePassResp, changePassErr] = await api({
    pk: params.id,
    data: {
      password: password,
      confirm_password: confirmPassword,
    },
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  if (!!changePassErr) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { error: changePassErr },
      },
    });
  } else {
    if (!params.noShowSuccess) {
      controller.setState({
        successMessage: { ...state.successMessage, [params.card]: true },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
      });
    }
  }

  return [changePassResp, changePassErr];
};

const PatchUserDetail: Handler = async (controller, params) => {
  const data = params.data;

  if (typeof data.image?.name === "string") {
    await UserDetailAPIViewM.patch({
      pk: params.userId,
      data: {
        image: data.image,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });
  }

  delete data.image;

  // update user detail
  return UserDetailAPIView.patch({
    pk: params.userId,
    data,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
};

const CreateUpdateDivisionHasUser: Handler = (controller, params) => {
  const dhu = params.dhu;

  const data = {
    user: dhu.user,
    division: dhu.division.id,
    triage_level: dhu.triage_level,
    extra: JSON.stringify(dhu.extra),
  };

  const api = !!dhu.id
    ? params.delete
      ? DivisionHasUserDetailForOtherUser.delete
      : DivisionHasUserDetailForOtherUser.patch
    : DivisionHasUserListForOtherUser.post;

  if (!!dhu.id) {
    delete data.user;
    delete data.division;
    delete data.triage_level;
  }

  return api({
    pk: !!dhu.id ? dhu.id : dhu.user,
    user_id: dhu.user,
    data: data,
    apiToken: controller.apiToken,
  });
};

export const GetStaffDetail: Handler = async (controller, params) => {
  console.log("GetStaffDetail: ");
  if (!params.userId) {
    return;
  }

  const staffRef = doc(controller.db, STAFF_DETAIL_EXTRA_COLLECTION, params.userId?.toString());
  const docSnap = await getDoc(staffRef);

  // const staff = await controller.db
  //   .collection(STAFF_DETAIL_EXTRA_COLLECTION)
  //   .doc(params.userId?.toString())
  //   .get();

  HandleLogEvent(controller, {
    function: "GetStaffDetail",
    readwrite: "read",
    event: "readFirestore",
  });

  let staffDetail = {
    extra: docSnap?.data() || {},
  };

  return staffDetail;
};

export const UpdateStaffDetail: Handler = async (controller, params) => {
  console.log("UpdateStaffDetail: ");
  const state = controller.getState();

  const extra = state.profileUser?.staff_extra;

  const data = {
    ...(extra || {}),
    user_id: params.id,
    doctor_id: params.doctor,
    member_group_permission: extra?.member_group_permission || [],
  };

  await setDoc(doc(controller.db, STAFF_DETAIL_EXTRA_COLLECTION, data.user_id?.toString()), data);

  // await controller.db
  //   .collection(STAFF_DETAIL_EXTRA_COLLECTION)
  //   .doc(data.user_id?.toString())
  //   .set(data);

  HandleLogEvent(controller, {
    function: "UpdateStaffDetail",
    readwrite: "write",
    event: "writeFirebase",
  });

  return data;
};

const HandleLogEvent: Handler = (controller, params) => {
  controller.analytics.logEvent(params.event, {
    function: params.function,
    document: STAFF_DETAIL_EXTRA,
    value: 1,
  });

  const log = new GrayLogger();

  log.debug(params.function, {
    _firebaseEvent: params.function,
    _firebaseHitCount: 1,
    _firebaseDocument: STAFF_DETAIL_EXTRA,
    _type: "firestore_log",
    _firebase_readwrite: params.readwrite,
  });
};

const GetDivisionhasUserList: Handler = async (controller, params) => {
  let result: any[] = [null, null, null];

  if (params.id) {
    result = await DivisionHasUserListForOtherUser.get({
      pk: params.id,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });
  }

  const divisionHasUser: any[] = (result[0]?.items || []).map((item: any) => ({
    ...item,
    extra: JSON.parse(item.extra),
  }));

  return divisionHasUser;
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

const SetErrorMessage: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.buttonLoadKey}`]: "ERROR",
    },
    errorMessage: {
      ...state.errorMessage,
      [params.errorKey || params.card]: { error: params.error },
    },
  });
};
